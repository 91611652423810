.loadMoneyTemplate {
  /* padding: 0 0 75px; */
  padding: 0;

}

.loadMoneyCard {
  width: 100%;
  margin: 5% auto 0;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(42 134 159 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  max-width: 450px;
}

.loadMoneyCard svg {
  /* width: 40px; */
  /* height: 40px; */
  fill: var(--primary);
}

.loadMoneyCard-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loadMoneyCard-btn button {
  max-width: 150px;
  margin: 0 10px;
  width: 200px;
  padding: 15px !important;
  font-size: 16px !important;
  letter-spacing: 1px;
}

.gift {
  background: var(--secondary) !important;
  color: var(--primary) !important;
}

.loadMoneyCard-text {
  color: #888;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
}

.loadMoneyCard-text svg {
  fill: #888;
  margin-right: 5px;
}

.loadMoneyCard-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
}

.loadMoneyCard-price input {
  border: 0;
  width: 200px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
}

.loadMoneyCard .key {
  width: 100%;
  direction: ltr !important;
  height: 200px;
}

.loadMoneyCard .key>* {
  position: static !important;
}

.loadMoneyCard .key>*>* {
  padding: 0;
}

.loadMoneyCard .key p {
  font-family: inherit !important;
  font-size: smaller;
  margin: 10%;
}

.templateHeading {
  margin: 0;
}

.backIcon {
  right: 0;
}

.backIcon svg {
  fill: var(--primary);
}

.arrowWrapper {
  position: relative;
  height: 20px;
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

.arrow {
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  /* left: 0; */
  /* bottom: 0; */
}

@keyframes MoveUpDown {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: 10px;
  }
}