.checkoutBlocks {
  margin: 40px 0;
}

.checkoutBlocks-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px;
}

.LinkBtn {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #333 !important;
  text-transform: uppercase !important;
  margin-top: 20px !important;
}

.checkoutBlocks .MuiFormControl-root {
  width: 100%;
  margin: 10px 0;
}

.PaymentCards {
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
  position: relative;
}

.PaymentCards__delete {
  position: absolute;
  left: 5px;
  bottom: 15px;
  font-size: 18px !important;
  color: red;
  transition: opacity 0.4s;
}

.PaymentCards__delete:hover {
  opacity: 0.7;
}

.PaymentCards__selected {
  outline: 2px solid #ccc;
}

.PaymentCards-img {
  width: 50px;
  height: 25px;
  margin-right: 15px;
}

.PaymentCards-img img {
  width: 60px;
  height: 25px;
  object-fit: contain;
}

.PaymentCards-no {
  font-size: 16px;
  color: #333;
  overflow-wrap: anywhere;
}

.checkoutFooterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 0 0;
}

.checkoutFooterBtn button {
  max-width: 450px;
  margin: 0 auto;
  border-radius: 12px;
  background-color: var(--primary) !important;
  color: white !important;
  padding: 15px 12px;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 24px;
  border: 1px solid var(--primary);
  width: 100%;
}

.cardTextNo {
  display: flex;
  align-items: center;
  position: relative;
}

.cardTextNo img {
  position: absolute;
  right: 10px;
  width: 50px;
}

.checkoutBlocks-mainImg img {
  width: 400px;
  height: 400px;
}

[dir="rtl"] .checkoutBlocks-title {
  text-align: right;
}

[dir="rtl"] .checkoutBlocks label {
  right: 25px;
  left: unset;
}