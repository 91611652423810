body {
  margin: 0;

  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container ::-webkit-scrollbar {
  height: 0;
}

/* Track */
.container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.container ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.isLink {
  cursor: pointer;
}

.arrow-rotate-top {
  transform: rotate(180deg);
}
