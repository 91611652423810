.vendorTemplate {
  padding: 40px 0 100px;
}

.centerTabs .TabsListUnstyled-horizontal {
  background-color: #eee;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 22px;
  max-width: 320px;
}

.centerTabs .TabsListUnstyled-horizontal button {
  color: #666;
  font-size: 14px !important;
  padding: 6px 16px;
}

.centerTabs .TabsListUnstyled-horizontal button:hover {
  background-color: transparent;
}

.centerTabs .TabsListUnstyled-horizontal button.Mui-selected {
  color: var(--primary);
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgb(0 0 0 / 10%);
}

.vendorContent {
  margin: 20px 0;
  max-width: 450px;
  text-align: right;
}

.vendorContent-title {
  font-size: 18px;
  color: #999;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 400;
}

.vendorContent-text {
  font-size: 14px;
  line-height: 18px !important;
  color: #999;
  line-height: 26px;
  margin-bottom: 5px;
  /* font-weight: bolder; */
}

.excerpt {
  font-style: italic;
  font-size: 12px;
}

.ContactUsModule {
  max-width: 400px;
  margin: 40px auto;
}

.ContactUsModule-title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.ContactUsModule .formBtn {
  text-align: center;
}

.ContactUsModule .formBtn button {
  width: auto;
  padding: 10px 30px !important;
  margin: 0 auto;
}

.reviewCard {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 30px;
  margin-top: 40px;
}

.reviewCard-head {
  box-shadow: 0px 0px 19px 0px rgb(0 0 0 / 3%);
  background: #fff;
  padding: 15px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.reviewCard-subtotal {
  font-size: 45px;
  font-weight: 700;
  color: #333;
  margin-bottom: 0;
}

.reviewCard-totel {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  margin-bottom: 0;
}

.reviewCard-Title {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
}

.reviewList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.reviewList .MuiLinearProgress-root {
  max-width: 420px;
  width: 100%;
  background-color: #ddd;
  height: 12px;
  border-radius: 10px;
}

.reviewList .MuiLinearProgress-bar {
  background-color: #008db2;
  border-radius: 15px;
}

.reviewList .MuiRating-root svg {
  fill: #008db2;
}

.vendorbanner {
  padding: 15px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.vendorbanner::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #00000040;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.vendorbanner-brand {
  display: flex;
  align-items: center;
}

.vendorbanner-brandImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 20px;
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgb(0 0 0 / 3%);
  display: block;
  overflow: hidden;
  border: 2px solid #fff;
}

.vendorbanner-brandImg img {
  object-fit: contain;
  height: 100px;
  width: 100px;
  padding: 12%;
}

.vendorbanner-brandTitle {
  font-size: 14px;
  color: #888;
  list-style: 22px;
  margin: 0;
}

.vendorbanner-brandSubtitle {
  font-size: 32px;
  color: #fff;
  list-style: 22px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.vendorAllListBlock {
  margin: 10px 0;
}

.vendorAllListBlock .module-heading__title {
  margin-bottom: 10px;
  text-align: right;
}

.category-boxSubTitle {
  position: absolute;
  left: 5px;
  top: 5px;
  color: var(--primary);
  background-color: white;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  padding: 5px;
  z-index: 1;
}

.vendorAllListBlock .categoryList {
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 991px) {
  .vendorbanner-brand {
    margin-bottom: 25px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .vendorAllListBlock .category-box {
    margin-right: 0;
  }

  .vendorAllListBlock .categoryList__block:nth-child(odd) .category-box {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .vendorAllListBlock .categoryList {
    padding: 0;
  }
}

.vendorSearch {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.vendorSearchTitle {
  margin-left: 20px !important;
}

.store-box__kosher {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #000;
  height: 30px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  opacity: 40% !important;
  color: white;
  font-size: 12px;
  z-index: 100;
}

.up {
  height: 28px;
  vertical-align: middle !important;
}
